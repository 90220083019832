import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { SkeletonDiamond } from '../icons';

const LeaderboardSectionSkeleton = ({ title }: { title?: string }) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title ? (
          <Typography
            sx={{ fontWeight: 500, fontSize: '24px', color: '#9B9FA3' }}
          >
            {title}
          </Typography>
        ) : (
          <Skeleton
            animation={false}
            variant="rounded"
            width={80}
            height={20}
          />
        )}
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Skeleton
            animation={false}
            variant="rounded"
            width={55}
            height={10}
          />
          <Skeleton
            animation={false}
            variant="rounded"
            width={55}
            height={10}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 4,
          border: '1px solid #222426',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {[...Array(5)].map((_, index) => (
            <Grid
              container
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 5,
                borderBottom: index < 5 ? '1px solid #222426' : 'none',
              }}
            >
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 4,
                  }}
                >
                  <SkeletonDiamond />
                  <Skeleton
                    animation={false}
                    variant="circular"
                    sx={{
                      width: { xs: 20, md: 40 },
                      height: { xs: 20, md: 40 },
                    }}
                  />
                  <Box>
                    <Skeleton animation={false} width={90} height={15} />
                    <Skeleton
                      animation={false}
                      width={52}
                      height={15}
                      sx={{ backgroundColor: '#111314' }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <Skeleton animation={false} width={90} height={15} />
                <Skeleton animation={false} width={52} height={15} />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <Skeleton animation={false} width={90} height={15} />
                <Skeleton animation={false} width={52} height={15} />
              </Grid>
            </Grid>
          ))}
        </Box>
        <Box sx={{ px: 4, py: 7 }}>
          <Skeleton
            animation={false}
            variant="rounded"
            width={100}
            height={10}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default LeaderboardSectionSkeleton;
